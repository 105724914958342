/** @jsx jsx */
import {jsx} from 'theme-ui';

const styles = {
    link: {
        color: `alpha`,
        textDecoration: `none`,
        ':hover': {
            color: `alphaDark`
        },
        ':visited': {
            color: `alpha`
        },
    }
};

const InternalLink = ({
                          to,
                          target,
                          ...props
                      }) => {
    return (
        <a sx={styles.link} href={to} target={target || '_self'} {...props}>
            {props.children}
        </a>
    );
};

export default InternalLink;