import React from 'react'
import {MDXProvider} from "@mdx-js/react"
import {MDXRenderer} from 'gatsby-plugin-mdx'
import ExternalLink from "woofandbeyond/src/links/ExternalLink";
import InternalLink from "../../../links/InternalLink";
import {affiliateProducts} from "../../../affiliateProducts";
import components from '@components/Mdx'
import {amazonAssociatesTag} from "../../../amazon";
import TableOfContentsExpanded from "../../../../../packages/flow-ui/flow-ui-widgets/src/TableOfContentsExpanded";

const shareasaleUser = process.env.GATSBY_SHAREASALE_USER;

const modifyUrl = (url) => {
    if (url.startsWith('/') || url.startsWith('#') || url.startsWith('https://www.woofandbeyond.com') ||
        url.startsWith('http://www.woofandbeyond.com')) {
        return [url, 'internal'];
    }

    if (url.startsWith('product:')) {
        const productName = url.replace('product:', '');
        if (productName in affiliateProducts) {
            let link = affiliateProducts[productName];
            if (link.includes('amazon.com')) {
                // Query parameter already in place, append another
                if (link.includes('?')) {
                    link = `${link}&tag=${amazonAssociatesTag}`
                } else {
                    link = `${link}?tag=${amazonAssociatesTag}`
                }
            } else if (link.includes('shareasale.com')) {
                link = `${link}&u=${shareasaleUser}&urllink=&afftrack=`
            }
            return [link, 'external-affiliate'];
        }

        console.log(`Could not find URL for product: "${productName}"`);

        return [productName, 'external-affiliate'];
    }

    return [url, 'external'];
}

export const PostBody = React.memo((post) => {
    const body = post.body;

    return <MDXProvider
        components={{
            ...components,
            p: props => {
                return (
                    <p {...props} css={theme => ({
                        color: theme.colors.article,
                        fontSize: '1.25rem',
                        margin: '0',
                        marginBottom: '2rem',
                        code: {
                            fontSize: `inherit`
                        }
                    })}/>
                );
            },
            h2: props => {
                const heading = <components.h2 {...props} />;

                const numberOfTableOfContentItems = post.tableOfContents?.items?.length || 0;
                if (numberOfTableOfContentItems < 2) {
                    return heading;
                }

                const firstHeading = post.tableOfContents?.items?.[0]?.title;
                const currHeading = props.children;


                if (firstHeading !== currHeading) {
                    return heading;
                }

                return (
                    <>
                        <TableOfContentsExpanded {...post} columns={1} useSection={false} useHeading={true}
                                                 variant="secondary"/>
                        {heading}
                    </>
                );
            },
            a: props => {
                const url = props.href;
                const [modifiedUrl, urlType] = modifyUrl(url);

                const modifiedProps = {
                    ...props,
                    href: modifiedUrl
                }

                // If internal link
                if (urlType === 'internal') {
                    return (
                        <InternalLink {...modifiedProps} />
                    );
                }

                const isAffiliate = urlType === 'external-affiliate';
                return (
                    <ExternalLink paid={isAffiliate} {...modifiedProps} />
                );
            }
        }}>
        <MDXRenderer>{body}</MDXRenderer>
    </MDXProvider>
});
