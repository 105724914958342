import React from 'react'
import {Link as GLink} from 'gatsby'
import {Flex, Card, Grid, Link, Heading} from 'theme-ui'
import Section from '@components/Section'
import themeStyles from '../../flow-ui-theme/theme/styles';

const styles = {
    number: {
        color: `omega`,
        width: `2rem`,
        mr: 3,
        mb: 0
    },
    text: {
        flex: `1`,
        ':hover': {
            color: 'alphaDark'
        },
        mb: 0
    },
    mainHeading: {
        fontWeight: 'bold',
        fontSize: '1.25rem',
        paddingBottom: '10px'
    }
}
const TableOfContentsExpanded = ({
                                     tableOfContents: {items = []},
                                     columns,
                                     useSection = true,
                                     useHeading = true,
                                     variant = 'paper'
                                 }) => {
    if (items.length === 0) {
        return null;
    }

    const card =
        <Card variant={variant}>
            <Grid
                sx={{
                    gridRowGap: 1,
                    gridAutoFlow: [`row`, null, `column`],
                    gridTemplateRows: [
                        `auto`,
                        null,
                        `repeat(${Math.ceil(items.length / columns)}, 1fr)`
                    ]
                }}
            >
                {items.map((item, index) => (
                    <Link
                        key={`item-${index}`}
                        as={GLink}
                        to={item.url}
                        variant='vertical'
                    >
                        <Flex sx={{alignItems: `center`}}>
                            <Heading variant='h3' as='div' sx={styles.number}>
                                {(index + 1).toLocaleString('en-US', {
                                    minimumIntegerDigits: 2,
                                    useGrouping: false
                                })}
                            </Heading>
                            <Heading variant='h4' as='div' sx={styles.text}>
                                {item.title}
                            </Heading>
                        </Flex>
                    </Link>
                ))}
            </Grid>
        </Card>;

    if (useSection) {
        return (
            <Section title={useHeading ? 'Table Of Contents' : undefined}>
                {card}
            </Section>
        );
    }

    if (useHeading) {
        return (
            <>
                <div style={themeStyles.h3} css={theme => ({
                    color: theme.colors.article,
                    ...styles.mainHeading
                })}>
                    Table of Contents
                </div>
                {card}
            </>
        );
    }

    return {card}
}

TableOfContentsExpanded.defaultProps = {
    columns: 2
}

export default TableOfContentsExpanded
