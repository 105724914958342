import React from 'react'
import {Card as CardComponent, Text, Box} from 'theme-ui'
import {Stack, Main, Sidebar, Layout} from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Sticky from '@components/Sticky'
import Seo from '@widgets/Seo'
// import AuthorCompact from '@widgets/AuthorCompact'
import {
    PostHead,
    PostImage,
    PostBody,
    PostComments,
    PostTagsShare,
    PostCommentsFacebook,
    PostCommentsGraph,
    // PostFooter
} from '@widgets/Post'
import AdPlaceholder, {adsConfig} from "../../../ads";
import {AmazonNativeAdsPlaceholder} from "../../../amazon";

const styles = {
    disclaimer: {
        fontWeight: `body`,
        fontSize: '1rem',
        color: `text`,
        fontStyle: 'italic',
        paddingBottom: '5px',
        mb: 0
    },
    mobileRelatedPosts: {
        '@media screen and (min-width: 1024px)': {
            display: `none`,
        },
    },
    mobileRelatedPostsSidebar: {
        '@media screen and (max-width: 1024px)': {
            display: `none`,
        },
    },
    postDisclaimers: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '25px'
    }
}

const Post = ({
                  data: {post, tagCategoryPosts, tagPosts, categoryPosts, previous, next},
                  ...props
              }) => {
    let relatedPosts = [
        ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
        ...(tagPosts ? tagPosts.nodes : []),
        ...(categoryPosts ? categoryPosts.nodes : []),
    ];
    relatedPosts = relatedPosts.filter((item, index) => relatedPosts.indexOf(item) === index && post.slug !== item.slug);

    const {pageContext: {services = {}, siteUrl} = {}} = props

    return (
        <Layout {...props}>
            <Seo {...post} isBlogPost={true}/>
            <Divider/>
            {/*<AdPlaceholder {...adsConfig.post.topOfPage} />*/}
            <Stack effectProps={{effect: 'fadeInDown'}}>
                <PostHead {...post} />
            </Stack>
            <Divider space={3}/>
            <Stack effectProps={{fraction: 0}}>
                <Main>
                    <CardComponent variant='paper'>
                        <PostImage {...post} inCard/>
                        <div css={styles.postDisclaimers}>
                            <Text variant='div' css={styles.disclaimer}>
                                <span role="img" aria-label="Editorial Disclaimer">✏️</span> This article has been
                                reviewed
                                in accordance with our editorial policy.
                            </Text>
                            {post.showMedicalDisclaimer && (
                                <Text variant='div' css={styles.disclaimer}>
                                    <span role="img" aria-label="Health Disclaimer">🏥</span> The information in this
                                    article is not a substitute for professional help.
                                </Text>
                            )}
                            {post.showAffiliateDisclaimer && (
                                <Text variant='div' css={styles.disclaimer}>
                                    <span role="img" aria-label="Affiliate Disclaimer">🔗</span> This article contains
                                    affiliate links. We may earn commission when you buy through them.</Text>
                            )}
                        </div>
                        <PostBody {...post} />
                        <Divider/>
                        <AmazonNativeAdsPlaceholder/>
                        <PostTagsShare {...post} location={props.location}/>
                        {services.disqus && <PostComments {...post} />}
                        {services.graphComment && <PostCommentsGraph {...post} />}
                        {services.facebookComment && (
                            <PostCommentsFacebook {...post} siteUrl={siteUrl}/>
                        )}
                        {/*<PostFooter {...{previous, next}} />*/}
                    </CardComponent>
                    <AdPlaceholder {...adsConfig.post.bottomOfPage} />
                    <Divider/>
                    <Box sx={styles.mobileRelatedPosts}>
                        {post.category && (
                            <CardList
                                title='Related Posts'
                                nodes={relatedPosts}
                                variant={['horizontal-md']}
                                columns={[1, 1, 1, 2]}
                                limit={6}
                                loading="lazy"
                                distinct
                            />
                        )}
                    </Box>
                </Main>
                <Sidebar sx={styles.mobileRelatedPostsSidebar}>
                    {/*<AuthorCompact author={post.author} omitTitle/>*/}
                    {/*<AuthorExpanded author={post.author} showSocialAndSkills={false}/>*/}
                    {/*<Divider/>*/}
                    {/*<Categories categories={categories}/>*/}
                    <AdPlaceholder {...adsConfig.post.sidebarTop} />
                    <Divider/>
                    {post.category && (
                        <CardList
                            title='Related Posts'
                            nodes={relatedPosts}
                            variant='horizontal-aside'
                            omitCategory
                            limit={6}
                            loading="lazy"
                            distinct
                            aside
                        />
                    )}
                    <Divider/>
                    <Sticky>
                        <AdPlaceholder {...adsConfig.post.sidebarBottom} />
                    </Sticky>
                </Sidebar>
            </Stack>
        </Layout>
    )
};

export default Post;
